<script setup>
import { SearchBar } from '@/components/searchbar';
import { useAxiosRepo } from '@pinia-orm/axios';
import { Paralegal } from '@/models';
import { useRoute } from 'vue-router';
import { ref, watch, onMounted } from 'vue';
import { useAuth } from '@/auth';
const auth = useAuth();
const route = useRoute();
const paralegalId = ref(null);
const paralegalRepo = useAxiosRepo(Paralegal);

watch(auth.user, () => {
    paralegalId.value = route.query.paralegalId || auth.user.settings?.default_paralegal || paralegalRepo.first().id;
})

onMounted(() => {
    paralegalId.value = route.query.paralegalId || auth.user.settings?.default_paralegal || paralegalRepo.first().id;
    
});

console.log('auth.user', auth.user)
</script>

<template>
    <div class="px-4 py-6 space-y-6 flex items-center justify-center lg:px-8">
        <div class="border-none p-0 outline-none flex flex-col items-center justify-center h-full w-full title-ref">
            <div class="items-center max-w-2xl justify-center">
                <div 
                    class="w-[5rem] h-[5rem] mb-4 bg-no-repeat bg-contain bg-center mx-auto"
                    style="background-image: var(--symbol);"
                ></div>
                
                <SearchBar class="search-bar-ref" v-if="paralegalId" size="lg" ctx="home" :id="paralegalId" />
            </div>
        </div>   
    </div>
</template>