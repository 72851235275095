<script setup>
import { ManageButton } from '@/components/paralegals';
import { defineProps } from 'vue';
import Upgrade from '@/components/Upgrade';

const props = defineProps({
    subscription: {
        type: String,
        required: true
    }
});

console.log(props.subscription);
</script>

<template>
<div class="flex h-[280px] shrink-0 items-center justify-center rounded-md border border-dashed" :class="{ 'border-secondary/50 bg-backgroundSecondary/50': subscription === 'free' }">
    <div class="mx-auto flex max-w-[420px] flex-col items-center justify-center text-center">
      <svg :class="{ 'opacity-50': subscription === 'free' }" xmlns="http://www.w3.org/2000/svg" width="35" height="35" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="1.8" stroke-linecap="round" stroke-linejoin="round" class="mr-2 lucide lucide-bolt"><path d="M21 16V8a2 2 0 0 0-1-1.73l-7-4a2 2 0 0 0-2 0l-7 4A2 2 0 0 0 3 8v8a2 2 0 0 0 1 1.73l7 4a2 2 0 0 0 2 0l7-4A2 2 0 0 0 21 16z"/><circle cx="12" cy="12" r="4"/></svg>
      <h3 :class="{ 'opacity-50': subscription === 'free' }" class="mt-4 text-lg font-semibold">
        Nog geen paralegals toegevoegd
      </h3>
      <p :class="{ 'opacity-50': subscription === 'free' }" class="mb-4 mt-2 text-sm text-muted-foreground">
        Je hebt nog een paralegals toegevoegd. Voeg er een toe.
      </p>
      <template v-if="subscription !== 'free'">
        <ManageButton 
          mode="new" 
          text="Aan de slag" 
          :icon="false" 
          class="text-sm"
        />
      </template>
      <template v-else>
        <Upgrade 
          text="Upgrade naar Pro" 
          variant="outline" 
          :icon="false" 
          size="sm" 
          :paralegal_card="true"
          
        />
      </template>
    </div>
  </div>
</template>