<script setup>
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from '@/components/ui/dialog'
import Share from '@/components/sidebar/areas/Share'
import { Button } from '@/components/ui/button';
import { defineProps, defineModel } from 'vue';
import { Sparkles } from 'lucide-vue-next';
import { useRouter } from 'vue-router';
import { PlanCard } from '@/components/settings';
import { useSubscriptionStore } from '@/stores/subscription';
const subscription = useSubscriptionStore();

const router = useRouter();

const open = defineModel({default: false})
const props = defineProps(['text', 'variant', 'icon', 'size', 'share', "open", "no_button", "paralegal_card"]);
const upgrade = () => {
  router.push({ name: 'checkout', query: { plan: 'pro', billing_period: 'monthly', quantity: 1 } });
}

const proFeatures = [
  'Alles van basic',
  'Search+',
  'Ongelimiteerde search credits',
  'Custom AI-paralegals',
  'Genereer onderzoeksrapportages'
];

const basicFeatures = [
  'Search (basic)',
  'Gelimiteerde search credits',
  'Search geschiedenis (1 week)',
  'Gelimiteerde search credits'
];

</script>

<template>
    <div>
        <Dialog :open="open" @update:open="open = !open">
            <DialogTrigger class="w-full" v-if="!props.no_button">
                <Button 
                  class="h-10 w-full" 
                  :class="{ 'rounded-b-xl rounded-t-none border-t-none border-secondary opacity-75': props.paralegal_card }"
                  :size="props.size" 
                  :variant="props.variant"
                >
                  <icon v-if="props.icon" class="w-4 h-4 mr-2" />
                  {{ props.text }}
                </Button>
            </DialogTrigger>
            <DialogContent>
            <DialogHeader>
                <DialogTitle><div class="flex items-center"><Sparkles class="w-5 h-5 mr-2" />Upgrade naar Pro</div></DialogTitle>
                <DialogDescription>
                    <div v-if="props.share">
                        Je credits zijn op!
                        Deel Zeno voor extra credits
                        <Share />
                        OF UPGRADE
                    </div>
                
                Upgrade naar Pro voor nog geen fractie van een gemiddeld uurtarief per maand en geniet van de volgende voordelen:
                </DialogDescription>
            </DialogHeader>
            <div class="grid grid-cols-2 gap-4">
                <PlanCard
                :class="'bg-background border-border border'"
                :plan="'Basic'"
                :features="basicFeatures"
                :price="'Gratis'"
                :isFree="subscription.plan === 'free'"
                />
                <PlanCard
                :class="'bg-accent border-secondary border '"
                :plan="'Pro'"
                :features="proFeatures"
                :price="'€89,- p/maand excl. btw'"
                :isFree="subscription.plan === 'free'"
                />
                
            </div>
            <DialogFooter>
                <Button @click="upgrade">Kies Pro</Button>
            </DialogFooter>
            </DialogContent>
        </Dialog>
    </div>
</template>

<style scoped>
ul {
  list-style: none;
}
</style>