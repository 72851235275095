import { useAxiosRepo } from '@pinia-orm/axios';
import { Paralegal } from '@/models';
import addFormats from 'ajv-formats';
import { api } from '@/plugins/api';
import Ajv from 'ajv';

const ajv = new Ajv({ useDefaults: true, removeAdditional: true });
addFormats(ajv);

export function useParalegalManager() {
    const paralegalRepo = useAxiosRepo(Paralegal).setAxios(api)

    async function getSchema(type) {
        let schema = (await api.get('/paralegals/schema', {params: {type: type}})).data
        if (schema && schema.$defs) {
            const resolveRefs = (obj) => {
                for (const key in obj) {
                    if (typeof obj[key] === 'object' && obj[key] !== null) {
                        
                        if (obj[key].allOf && obj[key].allOf.length === 1) {
                            obj[key] = obj[key].allOf[0];
                        }
                        if (obj[key].$ref && obj[key].$ref.startsWith('#/$defs/')) {
                            const refKey = obj[key].$ref.split('/').pop();
                            obj[key] = { ...schema.$defs[refKey], ...obj[key] };
                            delete obj[key].$ref;
                        }
                        resolveRefs(obj[key]);
                    }
                }
            };
            
            resolveRefs(schema);
            delete schema.$defs;
        }

        return schema;
    }

    function getParalegal(id) {
        return paralegalRepo.withAll().find(id);
    }

    async function newParalegal(type) {
        const schema = await getSchema(type)
        const paralegal = paralegalRepo.make({
            type: type,
            custom: true,
            settings: {}
        });
        
        function initializeSettings(settings, s) {
            console.log(s)
            ajv.validate(s, settings)
            console.log(settings)
            for (const key in s.properties) {
                if (!(key in settings)) {
                    if (s.properties[key].type === 'object') {
                        settings[key] = initializeSettings({}, s.properties[key]);
                    }
                }
            }
            return settings

        }

        paralegal.settings = initializeSettings(paralegal.settings, schema);
        paralegal.settings.json_schema = schema

        return paralegal;
    }

    function copyParalegal(paralegalId) {
        const paralegal = getParalegal(paralegalId);
        const newParalegal = paralegalRepo.make(paralegal);
        newParalegal.id = null;
        newParalegal.name = `${paralegal.name} (copy)`;
        newParalegal.custom = true;
        return newParalegal;
    }

    async function saveParalegal(paralegal) {
        const schema = await getSchema(paralegal.type);
        console.log(schema, paralegal.settings)
        const valid = ajv.validate(schema, paralegal.settings);
        console.log(paralegal.id)
        if (!valid) throw new Error('Invalid paralegal configuration');
        if (paralegal.id) {
            await paralegalRepo.api().put(`/paralegals/${paralegal.id}`, paralegal);
        } else {
            await paralegalRepo.api().post('/paralegals/', paralegal);
        }
    }

    async function deleteParalegal(paralegalId) {
        await paralegalRepo.api().delete(`/paralegals/${paralegalId}`, { delete: paralegalId });
    }

    return {
        getSchema,
        getParalegal,
        newParalegal,
        copyParalegal,
        saveParalegal,
        deleteParalegal
    }
}