<script setup>
import { FormField, FormItem, FormLabel, FormControl, FormDescription, FormMessage } from '@/components/ui/form';
import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import { useForm } from 'vee-validate';
import { defineModel } from 'vue';
import { toast } from 'vue-sonner';
import { AlertDialogAction } from '@/components/ui/alert-dialog';
import { api } from '@/plugins/api'

const user = defineModel();

const { handleSubmit, resetForm } = useForm({
    initialValues: user.value,
})

const onSubmit = handleSubmit(async (values) => {
    try {
        user.value.first_name = values.first_name
        user.value.last_name = values.last_name
        await api.put(`/users/${user.value.id}`, { first_name: values.first_name, last_name: values.last_name })
        toast.success('Je profiel is opgeslagen');
    } catch (error) {
        console.log(error)
        toast.error('Er is iets misgegaan bij het opslaan van je profiel');
    }
})
</script>

<template>
    <form class="flex flex-col h-full space-y-2 text-left" @submit="onSubmit">
        <div class="grid grid-cols-2 gap-x-4 gap-y-2">
            <FormField v-slot="{ componentField }" name="first_name" class="col-span-2">
                <FormItem>
                    <FormLabel>Voornaam</FormLabel>
                    <FormControl>
                        <Input type="text" :placeholder="user.first_name" v-bind="componentField" />
                    </FormControl>
                    <FormDescription>
                    </FormDescription>
                    <FormMessage />
                </FormItem>
            </FormField>

            <FormField v-slot="{ componentField }" name="last_name" class="col-span-2">
                <FormItem>
                    <FormLabel>Achternaam</FormLabel>
                    <FormControl>
                        <Input type="text" :placeholder="user.last_name" v-bind="componentField" />
                    </FormControl>
                    <FormDescription>
                    </FormDescription>
                    <FormMessage />
                </FormItem>
            </FormField>
        </div>

        <div class="col-span-2">
            <FormField v-slot="{ componentField }" name="email" class="col-span-1">
                <FormItem>
                    <FormLabel>Email</FormLabel>
                    <FormControl>
                        <Input type="text" :placeholder="user.email" v-bind="componentField" disabled class="w-full" />
                    </FormControl>
                    <FormDescription>
                        Je kunt je emailadres alleen wijzigen door contact op te nemen met support.
                    </FormDescription>
                    <FormMessage />
                </FormItem>
            </FormField>
        </div>
        <!-- <FormField v-slot="{ componentField }" name="tel" class="col-span-2">
                <FormItem>
                    <FormLabel>Telefoonnummer</FormLabel>
                    <FormControl>
                        <Input type="text" :placeholder="user.tel" v-bind="componentField" />
                    </FormControl>
                    <FormMessage />
                </FormItem>
            </FormField> -->


        <div class="flex flex-row space-x-2 pt-2">
            <AlertDialogAction asChild>
                <Button type="submit">
                    Opslaan
                </Button>
            </AlertDialogAction>
            <Button type="button" variant="outline" @click="resetForm">
                Reset
            </Button>
        </div>


    </form>
</template>